import { FirestoreDataConverter } from '@angular/fire/firestore';

/**
 * Model for Commerce Shop (Used for Commerce)
 */
export class CommerceShop {
  id: string;
  name: string;
  description: string;
  logo: string;
  banner: string;
  template: string;
  url: string;
  allowedEmails: string[];
  public: boolean;
  customer: string;
  primaryColor: string;
  promotionalText: string;
  favIcon: string;
  batPrice: number;
  shippingMargin: number;
  contact: {email: string, phone: string};
  socials: {
    facebook: string,
    instagram: string,
    twitter: string,
    youtube: string,
    linkedin: string
  };
  facebookPixelScript: string;
  googleAdsScript: string;
  organizationId: string;

  constructor(data?: Partial<CommerceShop>) {
    this.id = data?.id ?? '';
    this.name = data?.name ?? '';
    this.description = data?.description ?? '';
    this.logo = data?.logo ?? '';
    this.banner = data?.banner ?? '';
    this.template = data?.template ?? '';
    this.url = data?.url ?? '';
    this.allowedEmails = data?.allowedEmails ?? [];
    this.public = data?.public ?? false;
    this.customer = data?.customer ?? '';
    this.primaryColor = data?.primaryColor ?? '';
    this.promotionalText = data?.promotionalText ?? '';
    this.favIcon = data?.favIcon ?? '';
    this.batPrice = data?.batPrice ?? 0;
    this.shippingMargin = data?.shippingMargin ?? 0;
    this.contact = {
      email: data?.contact?.email ?? '',
      phone: data?.contact?.phone ?? '',
    };
    this.socials = {
      facebook: data?.socials?.facebook ?? '',
      instagram: data?.socials?.instagram ?? '',
      twitter: data?.socials?.twitter ?? '',
      youtube: data?.socials?.youtube ?? '',
      linkedin: data?.socials?.linkedin ?? '',
    };
    this.facebookPixelScript = data?.facebookPixelScript ?? '';
    this.googleAdsScript = data?.googleAdsScript ?? '';
    this.organizationId = data?.organizationId ?? '';
  }

  /**
   * Converter used to convert a shipment from firebase
   */
  static converter: FirestoreDataConverter<CommerceShop> = {
    toFirestore: (shop: CommerceShop) => {
      return {
        id: shop.id,
        name: shop.name.trim(),
        description: shop.description,
        logo: shop.logo,
        banner: shop.banner,
        template: shop.template,
        url: shop.url,
        allowedEmails: shop.allowedEmails,
        public: shop.public,
        customer: shop.customer,
        primaryColor: shop.primaryColor,
        promotionalText: shop.promotionalText,
        favIcon: shop.favIcon,
        batPrice: shop.batPrice,
        shippingMargin: shop.shippingMargin,
        contact: shop.contact,
        socials: shop.socials,
        facebookPixelScript: shop.facebookPixelScript,
        googleAdsScript: shop.googleAdsScript,
        organizationId: shop.organizationId,
      };
    },
    fromFirestore: (snapshot, options) => {
      return new CommerceShop({
        ...snapshot.data(options),
        id: snapshot.id,
      });
    }
  };
}
