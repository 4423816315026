import { inject, Injectable } from '@angular/core';
import { User } from '../models/user.model';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ObjectHelper } from "../helpers/object.helper";
import { Organization } from "../models/organization.model";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  private firestore = inject(Firestore);

  /**
   * Get a firebase user
   * @param uid The user id
   * @returns The user or null
   */
  public async getUserByUid(uid: string): Promise<User> {
    const ref = doc(this.firestore, 'users', uid).withConverter(User.converter);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      return null;
    }
    return docSnap.data();
  }

  /**
   * Save a user in firebase
   * @param id the id of the user
   * @param user The field of the user to update
   * @returns Update doc result
   */
  public saveUser(id: string, user: Partial<User>): Promise<void> {
    const ref = doc(this.firestore, 'users', id)
      .withConverter(User.converter);
    return updateDoc(ref, ObjectHelper.anonymize(user));
  }

  /**
   * Get all salesmen from firebase
   * @returns The list of users with attribute sales true
   */
  public getSales(): Observable<User[]> {
    return this.users$
      .pipe(map(users => users.filter(user => user.sales)));
  }

  /**
   * Get all users
   * @returns A query to listen users changes
   */
  public async getUsers(): Promise<User[]> {
    const q = query(collection(this.firestore, 'users'),
      orderBy('lastName', 'asc'))
      .withConverter(User.converter);
    return getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map(doc => doc.data());
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }

  /**
   * Subscribe to organization users
   * @param organization The organization
   * @returns void
   */
  async subscribeToOrganizationUsers(organization: Organization): Promise<void> {
    if (!organization) {
      this.users$.next([]);
      return;
    }
    const ref = collection(this.firestore, 'users');
    const uIds = [...organization.users, ...organization.administrators];
    const organizationUsers: User[] = [];
    for (let i = 0; i < uIds.length; i += 10) {
      const idList = uIds.slice(i, i + 10);
      const q = query(ref, where('id', 'in', idList))
        .withConverter(User.converter);
      const snapshot = await getDocs(q);
      const users = snapshot.docs.map(doc => doc.data());
      for (const user of users) {
        organizationUsers.push(user);
      }
    }
    return this.users$.next(organizationUsers
      .sort((a, b) => a.displayName.localeCompare(b.displayName)));
  }

  /**
   * Get users linked with the customer id
   * @param id The customer id
   * @returns Linked users
   */
  async usersByCustomer(id: string): Promise<User[]> {
    const q = query(
      collection(this.firestore, 'users'),
      where('customerIds', 'array-contains', Number(id))) // TODO: customer ids could be strings
      .withConverter(User.converter);
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }
    return snapshot.docs.map(doc => doc.data());
  }

  /**
   * Get users linked with the subcontractor id
   * @param id The subcontractor id
   * @returns Linked users
   */
  async usersBySubcontractor(id: string): Promise<User[]> {
    const q = query(
      collection(this.firestore, 'users'),
      where('subcontractorIds', 'array-contains', id))
      .withConverter(User.converter);
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }
    return snapshot.docs.map(doc => doc.data());
  }
}
