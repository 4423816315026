import {
  Overprint,
  Quantity
} from "./commerce-configuration.model";
import { FirestoreDataConverter } from "@angular/fire/firestore";
import { OrderState } from "../enums/order-state.enum";
import { UnitTypeEnum } from "../enums/unit-type.enum";
import { Material } from "./material.model";
import { Format } from "./format.model";
import { Finish } from "./finish.model";
import { Cutting } from "./cutting.model";
import { Drilling } from "./drilling.model";
import { Folding } from "./folding.model";
import { Binding } from "./binding.model";
import { Printing } from "./printing.model";
import { UUID } from "angular2-uuid";

export class WebProduct {
  public id: string;
  public reference: string;
  /* Image copied from product */
  public image: string;
  /* Calculated price for the selected variation */
  public price: number;
  /* The link with the product */
  public product: string;
  public order: string;
  public code: string;
  public customerDescription: string;
  /* ID of the shop */
  public shop: string;
  /* The quantity */
  public quantity: Quantity | null;
  public pageNumber: number;
  public overprints: Overprint[];
  /* With proof or not */
  public withProof = false;
  public material: Material | null;
  public printType: Printing | null;
  public format: Format | null;
  public finish: Finish | null;
  public cutting: Cutting | null;
  public drilling: Drilling | null;
  public folding: Folding | null;
  public binding: Binding | null;
  public coverMaterial: Material | null;
  /* The file path */
  public filePath: string;
  public fileName: string;
  public configuration: string;
  public description: string;
  public name: string;
  public unitPrice: number;
  public taxes: number;
  public state: OrderState;
  public updated: Date|null;
  /* Email for notifications (upload files, proof validation) */
  public email: string;
  /* firstName and LastName of the user */
  public contact: string;
  /* firstName and LastName of the user */
  public customer: string;
  public erpId: string;
  public variationId: string;
  public deliveryDate: Date|null;
  public transactionId: string;
  public orderId: string;
  public poId: string;
  public delay: number;
  public priceWithTaxes: number;
  public priceWithoutTaxes: number;
  public script: string;
  public proofState: boolean | null;
  public proofPrice: number;
  public proofTaxes: number;
  public proofDate: Date|null;
  public shipping: string;
  public shippingPrice: number;
  public shippingTaxes: number;
  public transportPrice: number;
  public transportTaxes: number;
  public unit: UnitTypeEnum;
  public dynamic: boolean;
  public fixedFile: boolean;
  organizationId: string;

  constructor(data?: Partial<WebProduct>) {
    this.id = data?.id ?? UUID.UUID();
    this.reference = data?.reference ?? '';
    this.image = data?.image ?? '';
    this.price = data?.price ?? 0;
    this.product = data?.product ?? '';
    this.order = data?.order ?? '';
    this.code = data?.code ?? '';
    this.customerDescription = data?.customerDescription ?? '';
    this.shop = data?.shop ?? '';
    this.quantity = data?.quantity ? new Quantity(data.quantity) : null;
    this.pageNumber = data?.pageNumber ? data.pageNumber : 0;
    this.withProof = data?.withProof ?? false;
    this.material = data?.material ? new Material(data.material) : null;
    this.overprints = data?.overprints?.map(o => new Overprint(o)) ?? [];
    this.printType = data?.printType ? new Printing(data.printType) : null;
    this.format = data?.format ? new Format(data.format) : null;
    this.finish = data?.finish ? new Finish(data.finish) : null;
    this.cutting = data?.cutting ? new Cutting(data.cutting) : null;
    this.drilling = data?.drilling ? new Drilling(data.drilling) : null;
    this.binding = data?.binding ? new Binding(data.binding) : null;
    this.folding = data?.folding ? new Folding(data.folding) : null;
    this.coverMaterial = data?.coverMaterial ? new Material(data.coverMaterial) : null;
    this.filePath = data?.filePath ?? '';
    this.fileName = data?.fileName ?? '';
    this.configuration = data?.configuration ?? '';
    this.description = data?.description ?? '';
    this.name = data?.name ?? '';
    this.shipping = data?.shipping ?? '';
    this.shippingPrice = data?.shippingPrice ?? 0;
    this.shippingTaxes = data?.shippingTaxes ?? 0;
    this.unitPrice = data?.unitPrice ?? 0;
    this.taxes = data?.taxes ?? 0;
    this.state = data?.state ?? OrderState.NEW_ORDER;
    this.updated = data?.updated ?? new Date();
    this.email = data?.email ?? '';
    this.contact = data?.contact ?? '';
    this.customer = data?.customer ?? '';
    this.erpId = data?.erpId ?? '';
    this.variationId = data?.variationId ?? '';
    this.deliveryDate = data?.deliveryDate ?? null;
    this.transactionId = data?.transactionId ?? '';
    this.orderId = data?.orderId ?? '';
    this.poId = data?.poId ?? '';
    this.delay = data?.delay ?? 0;
    this.priceWithTaxes = data?.priceWithTaxes ?? 0;
    this.priceWithoutTaxes = data?.priceWithoutTaxes ?? 0;
    this.script = data?.script ?? '';
    this.proofState = data?.proofState ?? null;
    this.proofPrice = data?.proofPrice ?? 0;
    this.proofTaxes = data?.proofTaxes ?? 0;
    this.proofDate = data?.proofDate ?? null;
    this.transportPrice = data?.transportPrice ?? 0;
    this.transportTaxes = data?.transportTaxes ?? 0;
    this.unit = data?.unit ?? UnitTypeEnum.Unit;
    this.dynamic = data?.dynamic ?? false;
    this.fixedFile = data?.fixedFile ?? false;
    this.organizationId = data?.organizationId ?? '';
  }

  /**
   * Converter used to convert a web product from firebase
   */
  static converter: FirestoreDataConverter<WebProduct> = {
    toFirestore: product => ({
      id: product.id,
      reference: product.reference,
      image: product.image,
      price: product.price,
      product: product.product,
      order: product.order,
      code: product.code,
      customerDescription: product.customerDescription,
      shop: product.shop,
      quantity: product.quantity,
      pageNumber: product.pageNumber,
      withProof: product.withProof,
      material: product.material,
      printType: product.printType,
      format: product.format,
      finish: product.finish,
      cutting: product.cutting,
      drilling: product.drilling,
      binding: product.binding,
      folding: product.folding,
      coverMaterial: product.coverMaterial,
      overprints: product.overprints,
      filePath: product.filePath,
      fileName: product.fileName,
      configuration: product.configuration,
      description: product.description,
      name: product.name,
      shipping: product.shipping,
      shippingPrice: product.shippingPrice,
      shippingTaxes: product.shippingTaxes,
      unitPrice: product.unitPrice,
      taxes: product.taxes,
      state: product.state,
      updated: product.updated,
      email: product.email,
      contact: product.contact,
      customer: product.customer,
      erpId: product.erpId,
      variationId: product.variationId,
      deliveryDate: product.deliveryDate,
      transactionId: product.transactionId,
      orderId: product.orderId,
      poId: product.poId,
      delay: product.delay,
      priceWithTaxes: product.priceWithTaxes,
      priceWithoutTaxes: product.priceWithoutTaxes,
      script: product.script,
      proofState: product.proofState,
      proofPrice: product.proofPrice,
      proofTaxes: product.proofTaxes,
      proofDate: product.proofDate,
      transportPrice: product.transportPrice,
      transportTaxes: product.transportTaxes,
      unit: product.unit,
      dynamic: product.dynamic,
      fixedFile: product.fixedFile,
      organizationId: product.organizationId,
    }),
    fromFirestore: (snapshot, options) =>{
      const data = snapshot.data(options);
      return new WebProduct(
        {
          ...data,
          id: snapshot.id,
          updated: data['updated'] ? new Date(data['updated'].seconds * 1000) : null,
          deliveryDate: data['deliveryDate'] ? new Date(data['deliveryDate'].seconds * 1000) : null,
          proofDate: data['proofDate'] ? new Date(data['proofDate'].seconds * 1000) : null,
        });
    }
  };
}
