import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  endAt,
  Firestore,
  getDoc,
  getDocs, limit,
  onSnapshot,
  orderBy, Query,
  query,
  startAt,
  Unsubscribe,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { Order } from '../models/order.model';
import { DataService } from './data.service';
import { UploadService } from './upload.service';
import { ProductOrder } from '../models/product-order.model';
import { OrderState } from '../enums/order-state.enum';
import { FileTask } from '../models/file-task.model';
import { FileImportTask } from '../models/file-import-task.model';
import { BehaviorSubject } from "rxjs";
import { ObjectHelper } from "../helpers/object.helper";
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { FileType } from "../enums/zip-file-type.enum";

type QueryData<T> = {
  startDate?: Date;
  endDate?: Date;
  unsubscribe?: Unsubscribe;
  data$: BehaviorSubject<T[]>;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Order service
 */
export class OrderService {

  private unsubscribeFileTasks: Unsubscribe;
  private fileTasks$: BehaviorSubject<FileTask[]>;
  private unsubscribeImportTasks: Unsubscribe;
  private importTasks$: BehaviorSubject<FileImportTask[]>;
  private customerOrderData: QueryData<Order> = {
    data$: new BehaviorSubject<Order[]>([])
  }

  constructor(
    private firestore: Firestore,
    private uploadService: UploadService,
    private functions: Functions,
    private dataService: DataService) {
  }

  /**
   * Get orders filtered by states
   * @returns The query observable
   */
  public getPrePressOrders(): Query<Order> {
    return query(collection(this.firestore, 'orders'),
      where('state', 'in', [
        OrderState.WAIT_FILES, OrderState.PROCESSING, OrderState.WAIT_BAT, OrderState.BAT_ACCEPTED,
        OrderState.BAT_REFUSED]),
      orderBy('date', 'desc'))
      .withConverter(Order.converter);
  }

  /**
   * Get orders between two dates
   * @param startDate The start date
   * @param endDate The end date
   * @returns The orders observable
   */
  public getOrdersBetweenDates(startDate: Date, endDate: Date): BehaviorSubject<Order[]> {
    if (startDate.getTime() === this.customerOrderData.startDate?.getTime() &&
      endDate.getTime() === this.customerOrderData.endDate?.getTime()) {
      this.customerOrderData.data$.next(this.customerOrderData.data$.value);
      return this.customerOrderData.data$;
    }
    if (this.customerOrderData.unsubscribe) {
      this.customerOrderData.unsubscribe();
    }
    this.customerOrderData.startDate = startDate;
    this.customerOrderData.endDate = endDate;
    const q = query(collection(this.firestore, 'orders'),
      orderBy('date', 'desc'),
      startAt(endDate),
      endAt(startDate))
      .withConverter(Order.converter);
    this.customerOrderData.unsubscribe = onSnapshot(q, snapshot => {
      if (snapshot.empty) {
        return this.customerOrderData.data$.next([]);
      }
      this.customerOrderData.data$.next(snapshot.docs.map(doc => doc.data()));
    });
    return this.customerOrderData.data$;
  }

  /**
   * Get orders filtered by customer id
   * @param startDate The start date
   * @param endDate The end date
   * @param customerId The customer id
   * @returns The query observable
   */
  public ordersByCustomer(startDate: Date, endDate: Date, customerId: string): Query<Order> {
    return query(collection(this.firestore, 'orders'),
      where('customerId', '==', Number(customerId)), //TODO: Customer id could be a string
      orderBy('date', 'desc'),
      startAt(endDate),
      endAt(startDate))
      .withConverter(Order.converter);
  }

  /**
   * Get orders by transactionId
   * @param transactionId The order transaction id
   * @returns The order or null
   */
  public getOrderByTransactionId(transactionId: string): Promise<Order | null> {
    const q = query(collection(this.firestore, 'orders'),
      where('transactionId', '==', transactionId))
      .withConverter(Order.converter);
    return getDocs(q)
      .then(snapshot => {
        if (snapshot.empty) {
          return null;
        }
        return snapshot.docs[0].data();
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Get orders by transactionId
   * @param transactionId The order transaction id
   * @returns The orders or null
   */
  async searchOrderByTransactionId(transactionId: string): Promise<Order[]> {
    const q = query(collection(this.firestore, 'orders'),
      where('transactionId', '>=', transactionId),
      where('transactionId', '<=', transactionId+ '\uf8ff'), limit(10))
      .withConverter(Order.converter);
    return getDocs(q)
      .then(snapshot => {
        if (snapshot.empty) {
          return [];
        }
        return snapshot.docs.map(doc => doc.data());
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Get an order by id
   * @param id The order id
   * @returns The order or null
   */
  public getOrderById(id: string): Promise<Order | null> {
    const ref = doc(this.firestore, 'orders', id).withConverter(Order.converter);
    return getDoc(ref)
      .then(snapshot => {
        if (!snapshot.exists()) {
          return null;
        }
        return snapshot.data();
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Update order state
   * @param orderId The firebase id of the order
   * @param state The required state
   * @returns The promise
   */
  public updateOrderState(orderId: string, state: number): Promise<void> {
    const ref = doc(this.firestore, 'orders', orderId).withConverter(Order.converter);
    return updateDoc(ref, { state });
  }

  /**
   * Remove an order
   * @param order The order to remove
   * @returns The promise
   */
  public delete(order: Order): Promise<void> {
    const ref = doc(this.firestore, 'orders', String(order.id)).withConverter(Order.converter);
    return deleteDoc(ref);
  }

  /**
   * Update an order
   * @param id The id of the order to update
   * @param partialOrder The fields of the order to update
   * @returns The promise
   */
  public update(id: string, partialOrder: Partial<Order>): Promise<void> {
    const ref = doc(this.firestore, 'orders', id).withConverter(Order.converter);
    return updateDoc(ref, ObjectHelper.anonymize(partialOrder));
  }

  /**
   * Get an access token
   * @param id The order transaction id
   * @param type The token type
   * @returns The query promise with the token url
   */
  public getAccessToken(id: string, type: number): Promise<string> {
    return httpsCallable<{
      id: string,
      type: number
    }, string>(this.functions, 'get_access_token')({ id, type })
      .then(result => result.data);
  }

  /**
   * Get order by id with token
   * @param id The order id
   * @param token The authorisation token
   * @returns A promise with the order
   */
  public getOrderWithToken(id: string, token: string): Promise<Order> {
    this.dataService.dataLoading = true;
    return httpsCallable<{
      id: string,
      token: string
    }, Order>(this.functions, 'get_order_by_token')({ id, token })
      .then(result => {
        const order: any = result.data;
        order.date = new Date(order.date._seconds * 1000);
        if (order.uploadsDate) {
          order.uploadsDate = order.uploadsDate ? new Date(order.uploadsDate._seconds * 1000) : null;
        }
        if (order.batDate) {
          order.batDate = order.batDate ? new Date(order.batDate._seconds * 1000) : null;
        }
        if (order.mailDate) {
          order.mailDate = order.mailDate ? new Date(order.mailDate._seconds * 1000) : null;
        }
        if (order.printDate) {
          order.printDate = order.printDate ? new Date(order.printDate._seconds * 1000) : null;
        }
        for (const qualityError of (order.qualityErrors ?? [])) {
          qualityError.date = qualityError.date ? new Date(qualityError.date._seconds * 1000) : null;
        }
        for (const product of (order.products ?? [])) {
          product.deliveryDate = product.deliveryDate ? new Date(product.deliveryDate._seconds * 1000) : null;
        }
        order.startDeliveryDate = order?.products?.length ?
          order.products.find(product => product.deliveryDate)?.deliveryDate :
          order?.startDeliveryDate?.seconds ? new Date(order.startDeliveryDate.seconds * 1000) : null;
        return new Order(order);
      })
      .catch(() => {
        return null;
      })
      .finally(() => this.dataService.dataLoading = false);
  }

  /**
   * Get order by id , checking customer right
   * @param id The order id
   * @param customerIds The list of customer ids allowed
   * @returns A promise with the order
   */
  public getOrderForCustomer(id: string, customerIds: number[]): Promise<Order | null> {
    const q = query(collection(this.firestore, 'orders'),
      where(documentId(), '==', id),
      where('customerId', 'in', customerIds))
      .withConverter(Order.converter);
    return getDocs(q)
      .then(snapshot => {
        if (snapshot.empty) {
          return null;
        }
        return snapshot.docs[0].data();
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Send files required email
   * @param id The order id
   * @returns The promise
   */
  public sendFileEmail(id: string): Promise<void> {
    return httpsCallable<{
      orderId: string
    }, void>(this.functions, 'send_files_email')({ orderId: id })
      .then(result => result.data);
  }

  /**
   * Synchronize an order from multipress
   * @param organizationId The order transactionId
   * @param transactionId The order transactionId
   * @returns The promise
   */
  async syncOrder(organizationId: string, transactionId: string): Promise<void> {
    await httpsCallable<
      {
        organizationId: string,
        transactionId: string,
      },
      void>(this.functions, 'sync_order')({ organizationId, transactionId });
  }

  /**
   * Send required bat email
   * @param id The order id
   * @returns The promise
   */
  public sendBatEmail(id: string): Promise<void> {
    return httpsCallable<{
      orderId: string
    }, void>(this.functions, 'send_bat_email')({ orderId: id })
      .then(result => result.data);
  }

  /**
   * Force wait for validation state
   * @param id The order id
   * @returns The promise
   */
  public forceValidation(id: string): Promise<void> {
    return httpsCallable<{
      orderId: string
    }, void>(this.functions, 'force_validation')({ orderId: id })
      .then(result => result.data);
  }

  /**
   * Save proof validation
   * @param orderId The order id
   * @returns The promise
   */
  public updateBat(orderId: string): Promise<HttpsCallableResult<void>> {
    return httpsCallable<{ orderId: string }, void>(this.functions, 'update_bat')({ orderId });
  }

  /**
   * Update product data
   * @param order The order id
   * @returns The promise
   */
  async updateBatChoice(order: Order): Promise<void> {
    const ref = doc(this.firestore, 'orders', order.id)
      .withConverter(Order.converter);
    await updateDoc(ref, { products: ObjectHelper.anonymize(order.products) });
  }

  /**
   * Create files processing task
   * @param orderId The order
   * @returns The promise
   */
  public updateFiles(orderId: string): Promise<HttpsCallableResult<void>> {
    return httpsCallable<{
      orderId: string
    }, void>(this.functions, 'update_files')({ orderId });
  }

  /**
   * Get the files sync tasks
   * @returns The query observable
   */
  public getFilesTasks(): BehaviorSubject<FileTask[]> {
    if (!this.fileTasks$) {
      this.fileTasks$ = new BehaviorSubject<FileTask[]>([]);
      const q = query(collection(this.firestore, 'file-tasks'),
        where('inProgress', '==', true))
        .withConverter(FileTask.converter);
      this.unsubscribeFileTasks = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          return this.fileTasks$.next([]);
        }
        this.fileTasks$.next(snapshot.docs.map(doc => doc.data()));
      })
    }
    return this.fileTasks$;
  }

  /**
   * Get the import files tasks
   * @returns The query observable
   */
  public getImportTasks(): BehaviorSubject<FileImportTask[]> {
    if (!this.importTasks$) {
      this.importTasks$ = new BehaviorSubject<FileImportTask[]>([]);
      const q = query(collection(this.firestore, 'file-import-tasks'),
        where('inProgress', '==', true))
        .withConverter(FileImportTask.converter);
      this.unsubscribeImportTasks = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          return this.importTasks$.next([]);
        }
        this.importTasks$.next(snapshot.docs.map(doc => doc.data()));
      })
    }
    return this.importTasks$;
  }

  /**
   * Create a new orders synchronisation task
   * @param orderId The order id
   * @param state The new order state
   * @returns A reference to the created document
   */
  public async createUpdateStateTask(orderId: string, state: OrderState): Promise<void> {
    const ref = collection(this.firestore, 'orders-states');
    await addDoc(ref, { date: new Date(), orderId, state, processed: false });
  }

  /**
   * Delete okto local service generated files for this product
   * @param product The product
   * @returns The promise
   */
  public async cleanOldFiles(product: ProductOrder): Promise<void> {
    await this.uploadService.removeFile(product.poFiles[0].path.replace('.pdf', '-bat.pdf'));
    await this.uploadService.removeFile(product.poFiles[0].path.replace('.pdf', '-report-bat.pdf'));
  }

  public unsubscribe(): void {
    if (this.customerOrderData.unsubscribe) {
      this.customerOrderData.unsubscribe();
      this.customerOrderData = {
        data$: new BehaviorSubject<Order[]>([])
      }
    }
    if (this.unsubscribeFileTasks) {
      this.unsubscribeFileTasks();
      this.fileTasks$ = null;
    }
    if (this.unsubscribeImportTasks) {
      this.unsubscribeImportTasks();
      this.importTasks$ = null;
    }
  }

  /**
   * Retrieve an url of the zipped files
   * @param orderId The orderId
   * @param poId The poId
   * @param types The types of files to include in the zip
   * @param originalName Should we use the uploaded filename
   * @returns The Promise
   */
  public getOrderZip(orderId: string, poId: string, types: FileType[], originalName: boolean): Promise<string> {
    return httpsCallable<
      { orderId: string, poId?: string, types: FileType[], originalName: boolean },
      { url: string }>(this.functions, 'get_order_zip')(
        { orderId, poId, types, originalName })
      .then(res => res.data.url)
  }
}
