import { inject, Injectable } from '@angular/core';
import {
  addDoc,
  collection, deleteDoc,
  doc,
  documentId, endAt,
  Firestore,
  getDoc,
  getDocs, limit,
  onSnapshot, orderBy, Query,
  query, startAt,
  Unsubscribe,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { BehaviorSubject } from "rxjs";
import { Quotation } from '../models/quotation.model';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { DataService } from './data.service';
import { QuotationState } from '../enums/quotation-state.enum';
import { Notification } from '../enums/notification.enum';
import { ObjectHelper } from "../helpers/object.helper";
import { OrganizationService } from "./organization.service";

@Injectable({
  providedIn: 'root',
})
export class QuotationService {
  firestore = inject(Firestore);
  functions = inject(Functions);
  dataService = inject(DataService);
  organizationService = inject(OrganizationService);

  private unsubscribeQuotations: Unsubscribe;
  private quotations$: BehaviorSubject<Quotation[]> = new BehaviorSubject<Quotation[]>([]);

  /**
   * get all organization users
   * @returns A query to listen users changes
   */
  public getOrganizationQuotations(): BehaviorSubject<Quotation[]> {
    return this.quotations$;
  }

  /**
   * Subscribe to organization quotations
   * @param organizationId The organization id
   */
  subscribe(organizationId: string): void {
    if (!organizationId) {
      this.quotations$.next([]);
      return;
    }
    const q = query(collection(this.firestore, 'quotations'),
      where('organizationId', '==', organizationId))
      .withConverter(Quotation.converter);
    this.unsubscribeQuotations = onSnapshot(q, snapshot => {
      if (snapshot.empty) {
        return this.quotations$.next([]);
      }
      this.quotations$.next(snapshot.docs.map(doc => doc.data()));
    })
  }

  /**
   * Get a quotation by id
   * @param id The quotation id
   * @returns The quotation or null
   */
  public async get(id: string): Promise<Quotation | null> {
    const ref = doc(this.firestore, 'quotations', id).withConverter(Quotation.converter);
    return getDoc(ref)
      .then(snapshot => snapshot.exists() ? snapshot.data() : null)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Get quotation by id , checking customer right
   * @param id The quotation id
   * @param customerIds The list of customer ids allowed
   * @returns The quotation or null
   */
  public async getForCustomers(id: string, customerIds: string[]): Promise<Quotation | null> {
    const q = query(collection(this.firestore, 'quotations'),
      where(documentId(), '==', id),
      where('customerId', 'in', customerIds))
      .withConverter(Quotation.converter);
    return getDocs(q)
      .then(snapshot => snapshot.empty ? null : snapshot.docs[0].data())
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Send a quotation
   * @param quotation The quotation to restore
   * @returns The quotation
   */
  async sendQuotation(quotation: Quotation): Promise<Quotation> {
    quotation.version = quotation.version++;
    quotation.state = QuotationState.SUBMITTED;
    await updateDoc(
      doc(this.firestore, 'quotations', quotation.id).withConverter(Quotation.converter),
      { state: quotation.state, version: quotation.version }
    );
    await this.createASyncStateTask(quotation, QuotationState.SUBMITTED);
    return quotation;
  }

  /**
   * Create a new ERP task synchronisation
   * @param quotation The quotation
   * @param state The target state
   */
  private async createASyncStateTask(quotation: Quotation, state: QuotationState): Promise<void> {
    await addDoc(
      collection(this.firestore, 'quotations-states'),
      {
        date: new Date(),
        quotationId: quotation.id,
        state,
        organizationId: quotation.organizationId,
        erpId: quotation.erpId,
        processed: false,
      });
  }

  /**
   * Restore quotation to new state
   * @param quotation The quotation to restore
   * @returns The quotation
   */
  async restoreQuotation(quotation: Quotation): Promise<Quotation> {
    const cleanData: Partial<Quotation> = {
      state: QuotationState.NEW,
      emailSent: Notification.NONE_SENT,
      reason: '',
      signature: '',
    };
    await updateDoc(
      doc(this.firestore, 'quotations', quotation.id).withConverter(Quotation.converter),
      cleanData
    );
    await this.createASyncStateTask(quotation, QuotationState.NEW);
    return new Quotation({
      ...quotation,
      ...cleanData,
    })
  }

  /**
   * Get quotation by id with token
   * @param id The order id
   * @param token The authorisation token
   * @returns The quotation
   */
  public async getWithToken(id: string, token: string): Promise<Quotation> {
    this.dataService.dataLoading = true;
    return httpsCallable<{ id: string, token: string }, Quotation>(this.functions, 'get_quotation_by_token')({
      id,
      token
    })
      .then(result => {
        const quotation: any = result.data;
        if (quotation.date) {
          quotation.date = quotation.date ? new Date(quotation.date._seconds * 1000) : null;
        }
        if (quotation.validationDate) {
          quotation.validationDate = quotation.validationDate ?
            new Date(quotation.validationDate._seconds * 1000) : null;
        }
        if (quotation.lockDate) {
          quotation.lockDate = quotation.lockDate ? new Date(quotation.lockDate._seconds * 1000) : null;
        }
        return new Quotation(quotation);
      })
      .catch(err => {
        console.error(err);
        throw err;
      })
      .finally(() => this.dataService.dataLoading = false);
  }

  /**
   * Update the quotation response
   * @param quotation The quotation
   * @returns void
   */
  public async updateQuotation(quotation: Quotation): Promise<void> {
    return httpsCallable<Quotation, void>(this.functions, 'update_quotation')(quotation)
      .then(result => result.data)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Synchronize a quotation from multipress
   * @param id The quotation id
   * @param organizationId The organization Id
   * @returns void
   */
  public async synchronise(id: string, organizationId: string): Promise<void> {
    return httpsCallable<{ id: string, organizationId: string }, void>
    (this.functions, 'sync_quotation')({ id, organizationId })
      .then(result => result.data);
  }

  /**
   * Get signed url
   * @param id The quotation id
   * @returns The query promise with the token url
   */
  public async getSignedUrl(id: string): Promise<string> {
    return httpsCallable<{ id: string }, string>(this.functions, 'get_quotation_signed_url')({ id })
      .then(result => result.data);
  }

  /**
   * Send quotation email
   * @param id The quotation id
   * @returns void
   */
  public async sendEmail(id: string): Promise<void> {
    return httpsCallable<{ id: string }, void>(this.functions, 'send_quotation_email')({ id })
      .then(result => result.data);
  }

  /**
   * Update a quotation
   * @param id The id of the order to update
   * @param partialQuotation The fields of the quotation to update
   * @returns void
   */
  public update(id: string, partialQuotation: Partial<Quotation>): Promise<void> {
    const ref = doc(this.firestore, 'quotations', id).withConverter(Quotation.converter);
    return updateDoc(ref, ObjectHelper.anonymize(partialQuotation));
  }

  /**
   * Update a quotation
   * @param id The id of the quotation to delete
   * @returns void
   */
  public delete(id: string): Promise<void> {
    const ref = doc(this.firestore, 'quotations', id).withConverter(Quotation.converter);
    return deleteDoc(ref);
  }

  /**
   * Get orders by erpId
   * @param erpId The erp id
   * @returns The quotations or an empty array
   */
  async searchOrderByTransactionId(erpId: string): Promise<Quotation[]> {
    const q = query(collection(this.firestore, 'quotations'),
      where('erpId', '>=', erpId),
      where('erpId', '<=', erpId + '\uf8ff'), limit(10))
      .withConverter(Quotation.converter);
    return getDocs(q)
      .then(snapshot => {
        if (snapshot.empty) {
          return [];
        }
        return snapshot.docs.map(doc => doc.data());
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Get orders filtered by customer id
   * @param startDate The start date
   * @param endDate The end date
   * @param customerId The customer id
   * @returns The query observable
   */
  public quotationsByCustomer(startDate: Date, endDate: Date, customerId: string): Query<Quotation> {
    return query(collection(this.firestore, 'quotations'),
      where('customerId', '==', customerId),
      orderBy('date', 'desc'),
      startAt(endDate),
      endAt(startDate))
      .withConverter(Quotation.converter);
  }

  /**
   * Unsubscribe from quotations and clean cached quotations
   */
  unsubscribe(): void {
    if (this.unsubscribeQuotations) {
      this.unsubscribeQuotations();
      this.quotations$.next([]);
    }
  }
}
